<template>
  <div style="margin: 20px">
    <el-card>
      <el-form :model="form" v-loading="loading" :rules="rules" ref="form">
        <el-form-item label="Наименование" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save" :disabled="lockSaveButton">
            {{ isCreate ? 'Создать' : 'Сохранить' }}
          </el-button>
          <el-button v-if="form.is_deleted && !isCreate" type="success" @click="recoverActivity"
                     :disabled="lockRecoveryButton">Восстановить
          </el-button>
          <el-button v-if="!form.is_deleted && !isCreate" type="danger" @click="deleteActivity"
                     :disabled="lockDeleteButton">Удалить
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 10px">
      <label>История изменений</label>
      <simple-table style="margin-top: 10px" name="historyActivity" :columns="columns" :list="historyData" v-loading="loading"/>
    </el-card>
  </div>
</template>

<script>
import {
  getMainDirectionActivity,
  saveMainDirectionActivity,
  deleteMainDirectionActivity,
  recoverMainDirectionActivity,
  getMainDirectionActivityHistory
} from '@/api/dictionaries'
import SimpleTable from '@/components/SimpleTable'

export default {
  components: {
    SimpleTable
  },
  data () {
    return {
      loading: false,
      lockSaveButton: false,
      lockDeleteButton: false,
      lockRecoveryButton: false,
      isCreate: false,
      historyData: [],
      columns: [
        {
          field: 'action_tstamp',
          type: 'date',
          label: 'Время изменения'
        },
        {
          field: 'value.name',
          label: 'Наименование'
        },
        {
          field: 'editor_fio',
          label: 'Кто изменил'
        },
        {
          field: 'value.is_deleted',
          label: 'Удален'
        }
      ],
      form: {
        id: null,
        name: null,
        is_deleted: null
      },
      rules: {
        name: [
          {
            validator: (rule, value, callback) => {
              if (value?.trim() === '' || !value) {
                callback(new Error('Не заполнено обязательное поле'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted () {
    if (this.$route.name.includes('create')) {
      this.isCreate = true
    } else {
      this.fetchData()
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      getMainDirectionActivity(this.$route.params.id).then(response => {
        this.form = response.data
      }).finally(() => {
        this.loading = false
      })
      getMainDirectionActivityHistory(this.$route.params.id).then(response => {
        this.historyData = response.data.model
      })
    },
    save () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.lockSaveButton = true
          saveMainDirectionActivity(this.form).then((response) => {
            this.$message({
              message: 'Успешно сохранено!',
              type: 'success'
            })
            if (this.isCreate) {
              this.$router.push({ name: this.$route.name.replace('create', 'view'), params: { id: response.data.id } })
            } else {
              this.fetchData()
            }
          }).finally(() => {
            this.lockSaveButton = false
          })
        }
      })
    },
    deleteActivity () {
      this.lockDeleteButton = true
      deleteMainDirectionActivity(this.form.id).then(() => {
        this.$message({
          message: 'Успешно удалено!',
          type: 'success'
        })
        this.fetchData()
      }).finally(() => {
        this.lockDeleteButton = false
      })
    },
    recoverActivity () {
      this.lockRecoveryButton = true
      recoverMainDirectionActivity(this.form.id).then(() => {
        this.$message({
          message: 'Успешно восстановлено!',
          type: 'success'
        })
        this.fetchData()
      }).finally(() => {
        this.lockRecoveryButton = false
      })
    }
  }
}
</script>

<style scoped>

</style>
