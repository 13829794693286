import { render, staticRenderFns } from "./MainDirectionActivityView.vue?vue&type=template&id=3fb29922&scoped=true&"
import script from "./MainDirectionActivityView.vue?vue&type=script&lang=js&"
export * from "./MainDirectionActivityView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fb29922",
  null
  
)

export default component.exports